var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fluid",attrs:{"id":"myBooking"}},[(!_vm.getIsLoading)?_c('div',[(_vm.permission.read_perm === 1)?_c('div',{staticClass:"my-booking-list-div"},[_c('v-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-currency-usd","title":"Currency"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{staticClass:"my-booking-list-toolbar-1",attrs:{"flat":""}},[_c('v-btn',{staticClass:"mx-2 elevation-4",staticStyle:{"position":"relative","top":"-20px","left":"-10px","height":"80px","width":"80px"},attrs:{"dark":"","large":"","color":"cyan"}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v(" mdi-calendar-multiple ")])],1),_c('v-toolbar-title',[_vm._v("My Booking")]),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","margin-left":"10px","font-size":"12px"},attrs:{"label":"Status","items":[
                    { id: '', name: 'All' },
                    { id: 1, name: 'Approved' },
                    { id: 0, name: 'Pending' },
                    { id: -1, name: 'Rejected' }
                  ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},model:{value:(_vm.paramAPI.status),callback:function ($$v) {_vm.$set(_vm.paramAPI, "status", $$v)},expression:"paramAPI.status"}})],1),_c('v-btn',{staticClass:"ma-4 green--text booking-list-toolbar-btn",attrs:{"disabled":_vm.permission.create_perm === 0,"tile":"","elevation":"0","large":""},on:{"click":function($event){return _vm.$router.push('/meeting-activity/booking-form')}}},[_vm._v(" Buat Permintaan ")])],1),_c('v-toolbar',{staticClass:"my-booking-list-toolbar-2",attrs:{"flat":""}},[_c('v-btn',{staticClass:" green--text",attrs:{"tile":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.$router.push('/meeting-activity/booking-form')}}},[_vm._v(" Buat Permintaan ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 row-pointer",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.getRoomBookingList,"items-per-page":10,"loading":_vm.loading},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.start_use",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.start_use))+" ")])]}},{key:"item.end_use",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.end_use))+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('div',{style:(("padding:0; display:flex;   flex-direction:row; color:" + (item.status.id === 1
                        ? 'green'
                        : item.status.id === -1
                        ? 'red'
                        : 'grey')))},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}}],null,true)})],1)],1)],1)],1):_c('div',{staticStyle:{"height":"83vh","padding-top":"50px"}},[_c('AccessDenied')],1)]):_c('Loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }