<template>
  <div class="fluid" id="myBooking">
    <div v-if="!getIsLoading">
      <div v-if="permission.read_perm === 1" class="my-booking-list-div">
        <v-card icon="mdi-currency-usd" title="Currency" class="px-5 py-3">
          <v-row>
            <v-col cols="12">
              <v-toolbar class="my-booking-list-toolbar-1" flat>
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-calendar-multiple
                  </v-icon>
                </v-btn>
                <v-toolbar-title>My Booking</v-toolbar-title>
                <v-divider class="mx-6" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <div style="width:150px;">
                  <v-select
                    label="Status"
                    style="position: relative; top:15px; margin-left:10px; font-size:12px;"
                    v-model="paramAPI.status"
                    :items="[
                      { id: '', name: 'All' },
                      { id: 1, name: 'Approved' },
                      { id: 0, name: 'Pending' },
                      { id: -1, name: 'Rejected' }
                    ]"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                  ></v-select>
                </div>
                <v-btn
                  :disabled="permission.create_perm === 0"
                  tile
                  elevation="0"
                  large
                  class="ma-4 green--text booking-list-toolbar-btn"
                  @click="$router.push('/meeting-activity/booking-form')"
                >
                  Buat Permintaan
                </v-btn>
              </v-toolbar>
              <v-toolbar class="my-booking-list-toolbar-2" flat>
                <v-btn
                  tile
                  small
                  elevation="0"
                  class=" green--text"
                  @click="$router.push('/meeting-activity/booking-form')"
                >
                  Buat Permintaan
                </v-btn>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                class="elevation-1 row-pointer"
                style="cursor:pointer;"
                :headers="headers"
                :items="getRoomBookingList"
                :items-per-page="10"
                :loading="loading"
                @click:row="rowClick"
              >
                <template v-slot:[`item.start_use`]="{ item }">
                  <div style="padding:0; display:flex; flex-direction:row;">
                    {{ convertDate(item.start_use) }}
                  </div>
                </template>
                <template v-slot:[`item.end_use`]="{ item }">
                  <div style="padding:0; display:flex; flex-direction:row;">
                    {{ convertDate(item.end_use) }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :style="
                      `padding:0; display:flex;   flex-direction:row; color:${
                        item.status.id === 1
                          ? 'green'
                          : item.status.id === -1
                          ? 'red'
                          : 'grey'
                      }`
                    "
                  >
                    {{ item.status.name }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-else style="height:83vh; padding-top:50px;">
        <AccessDenied />
      </div>
    </div>
    <Loader v-else />
  </div>
</template>
<script>
import Loader from '../../../components/common/loader/CircularProgress'
import AccessDenied from '../../../components/common/accessDenied/accessDenied'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    drawer: false,

    menu_key: 24,
    permission: {},
    application: {
      id: 9,
      name: 'Room Activity'
    },
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 100,
      status: '',
      room_id: ''
    },
    headers: [
      {
        text: 'Booking No',
        value: 'booking_no',
        align: 'left',
        sortable: true
      },
      {
        text: 'Ruang',
        value: 'room.name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl/Waktu Pakai',
        value: 'start_use',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl/Waktu Berakhir',
        value: 'end_use',
        align: 'left',
        sortable: true
      },
      {
        text: 'Jenis Meeting',
        value: 'meeting_type',
        align: 'left',
        sortable: true
      },
      {
        text: 'Jml Peserta',
        value: 'number_of_attendance',
        align: 'left',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true
      }
    ],
    showForm: false,
    totalBookingData: 1,
    options: {},
    loading: false
  }),
  components: {
    Loader,
    AccessDenied
  },
  watch: {
    'paramAPI.status'() {
      this.initBookingList()
    }
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            this.initBookingList()
            setTimeout(() => {
              this.setIsLoading(false)
            }, 1000)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 1000)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        this.setPermission({ read_perm: 0 })
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1000)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLogin',
      'getRoomBookingList',
      'getUserProfile',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'menu_access',
      'logout',
      'roomBookingList',
      'bookingDetail',
      'roomDropdown'
    ]),
    ...mapMutations([
      'setBookingDetail',
      'setRoomBookingId',
      'setIsLoading',
      'setPermission'
    ]),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            timer: 5000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    drawerSet(val) {
      this.drawer = val
    },

    initBookingList() {
      this.loading = true
      this.paramAPI.keyword = this.getUserProfile.employee.email
      this.roomBookingList(this.paramAPI)
        .then(res => {
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },

    rowClick(pValue) {
      this.setRoomBookingId(pValue)
      this.bookingDetail()
        .then(res => {
          if (res.status_code === '00') {
            this.setBookingDetail(res)
            this.roomDropdown(this.getUserProfile)
            this.$router.push('/meeting-activity/my-booking/document')
          } else {
            this.showMsgDialog('error', 'status code: ' + res.status_code)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
        })
    },
    convertDate(raw) {
      const date = new Date(raw)
      return date.toLocaleString()
    }
  }
}
</script>
<style lang="scss" src="">
#myBooking {
  margin-bottom: 10px;
  .my-booking-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .my-booking-list-toolbar-1 {
      border-radius: 10px 10px 0 0;
    }

    .my-booking-list-toolbar-2 {
      display: none;
      height: 60px;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
@media (max-width: 576px) {
  #myBooking {
    .my-booking-list-div {
      .my-booking-list-toolbar-1 {
        .booking-list-toolbar-btn {
          display: none;
        }
      }

      .my-booking-list-toolbar-2 {
        display: flex;
      }
    }
  }
}
</style>
